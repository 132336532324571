import { combine, guard, sample, split } from "effector";

import intersect from "@turf/intersect";

import {
  getMinZoomInRange,
  zoomRangeInRecord,
} from "components/wizard/select-model/lib";

import { wizardDomain } from "models/root";

import { getAllowedZoom, TGetAllowedZoom } from "shared/api/client";
import { isS3 } from "shared/api/helpers";
import { setNull, setPayload } from "shared/lib/effector/helpers";
import { reset } from "shared/lib/effector/reset";
import { validateGeometry } from "shared/lib/geojson-validation";
import { FeatureValidationResult } from "shared/lib/geojson-validation/types";
import { getRandomProcessingName } from "shared/lib/string/get-random-processing-name";

import { ITiff, ModelId, Schema, TDataInputType } from "types";

import { ModelsZoomDefinition } from "entities/model/const";
import { spread } from "patronum";

import { Aoi, SourcePayload } from "../../types/wizard";
import events, { TCopyConfigParams } from "./events";
import {
  ClearModelOptions,
  createOptionFilter,
  ToggleModelOption,
} from "./helpers";
import { getNotAlowedOptions } from "./helpers";
import stores from "./stores";

type TCopyForDrawingParams = TCopyConfigParams & {
  inputType: Extract<TDataInputType, "drawing">;
};
type TCopyForRasterParams = TCopyConfigParams & {
  inputType: Extract<TDataInputType, "tiff">;
  file: ITiff;
};

const {
  setAOI,
  setSource,
  setDefaultSources,
  setModel,
  setSelectedProcessing,
  setProcessingName,
  setSchema,
  toggleModelOption,
  setSingleModelOption,
  copyProcessingConfig,
  clearAOI,
  clearSelectedProcessing,
  clearWizard,
  clearSource,
  setRasterFile,
  setInputType,
  copyProcessingForDrawing,
  copyProcessingForDrawingDataProvider,
  copyProcessingForRaster,
  setParams,
  resetParams,
  setUserInfo,
  setSharedRole,
  clearSharedRole,
  setImageryType,
  clearImageryType,
} = events;

const {
  $aoi,
  $usefulAOI,
  $source,
  $modelId,
  $selectedProcessing,
  $schema,
  $defaultSources,
  $modelOptionsIds,
  $notAllowedOptions,
  $processingName,
  $rasterFile,
  $inputType,
  $isDisabled,
  $params,
  $userInfo,
  $sharedUserRole,
  $imageryType,
} = stores;

const getAllowedZoomFx = wizardDomain.createEffect(getAllowedZoom);

const $allowedZoom = wizardDomain.createStore<Record<number, boolean> | null>(
  null
);

const $zoomInRange = combine(
  { allowedZoom: $allowedZoom, modelId: $modelId, type: $inputType },
  ({ allowedZoom, modelId }) => {
    if (!modelId || !allowedZoom) return { recommended: true, required: true };
    const def = ModelsZoomDefinition[modelId];
    return {
      recommended: zoomRangeInRecord(def?.recommended, allowedZoom),
      required: zoomRangeInRecord(def?.required, allowedZoom),
    };
  }
);

const $preferZoom = combine(
  { allowedZoom: $allowedZoom, modelId: $modelId },
  ({ allowedZoom, modelId }) => {
    if (!modelId) return 0;
    const def = ModelsZoomDefinition[modelId];
    return (
      getMinZoomInRange(def?.recommended, allowedZoom) ||
      getMinZoomInRange(def?.required, allowedZoom)
    );
  }
);

// BASIC DATA FLOW
$defaultSources.on(setDefaultSources, setPayload);

$inputType.on(setInputType, setPayload);

$aoi.on(setAOI, setPayload).reset(clearAOI);
$source.on(setSource, setPayload);
$modelId.on(setModel, setPayload);
$schema.on(setSchema, setPayload);

$processingName
  .on(setProcessingName, setPayload)
  .on(clearWizard, () => getRandomProcessingName());

$selectedProcessing
  .on(setSelectedProcessing, setPayload)
  // .on(setAOI, setNull)
  .reset([clearSelectedProcessing]);

$rasterFile.on(setRasterFile, setPayload);

$params.on(setParams, setPayload).reset(resetParams);

$userInfo.on(setUserInfo, setPayload);

$sharedUserRole.on(setSharedRole, setPayload).reset(clearSharedRole);

$imageryType.on(setImageryType, setPayload).reset(clearImageryType);

// sample({
//   clock: [$schema, clearWizard],
//   source: [$schema],
//   fn: ([schema]): ModelId | null => {
//     if (schema?.models.length === 1) {
//       return schema.models[0].id;
//     }

//     return null;
//   },
//   target: $modelId,
// });

sample({
  clock: [clearSource, clearWizard],
  source: $defaultSources,
  fn: (sources) => (sources ? sources.mapbox : null),
  target: $source,
});

// On setAOI -> clear modelOptionsIds
sample({
  clock: [setAOI],
  source: [$modelOptionsIds],
  fn: ([modelOptionsIds]) => modelOptionsIds,
  target: $modelOptionsIds,
});

sample({
  source: { $aoi, $rasterFile },
  fn: ({ $aoi, $rasterFile }) => {
    if (!$aoi || !$rasterFile) return null;
    // @ts-expect-error
    return intersect($aoi, $rasterFile.aoi)?.geometry ?? null;
  },
  target: $usefulAOI,
});

const $usefulValidResult = sample({
  source: $usefulAOI,
  fn: (geometry): FeatureValidationResult => {
    if (geometry) return validateGeometry(geometry);
    return { valid: false, invalidReason: null };
  },
});

// on toggleModelOption event take data from source, pass clock and source to fn, after that pass result fn to target
sample({
  clock: toggleModelOption,
  source: $modelOptionsIds,
  fn: (options, payload) => ToggleModelOption(options, payload),
  target: $modelOptionsIds,
});

sample({
  clock: setSingleModelOption,
  source: $modelOptionsIds,
  fn: (_options, modelId) => [modelId],
  target: $modelOptionsIds,
});

sample({
  clock: setModel,
  source: { options: $modelOptionsIds },
  fn: ({ options }) => options.filter((item) => !item),
  target: $modelOptionsIds,
});

//calculating not allowed options
sample({
  source: { $inputType, $aoi, $rasterFile },
  fn: ({ $inputType, $aoi, $rasterFile }) => {
    if ($inputType === "tiff" && $rasterFile) {
      return getNotAlowedOptions($rasterFile.aoi);
    }

    if ($inputType === "drawing" && $aoi) {
      return getNotAlowedOptions($aoi);
    }

    return [];
  },
  target: $notAllowedOptions,
});

// on copyConfig send values in stores
split({
  source: copyProcessingConfig,
  match: {
    isDataProvider: (config) =>
      config?.source?.data_provider && !config.source?.url,
    isDrawing: (config) => config.source?.url?.trim()?.startsWith("http"),
    isRasterFile: (config) => isS3(config.source?.url),
  },
  cases: {
    isDrawing: copyProcessingForDrawing,
    isRasterFile: copyProcessingForRaster,
    isDataProvider: copyProcessingForDrawingDataProvider,
  },
});

sample({
  clock: [copyProcessingForDrawing],
  fn: (config): TCopyForDrawingParams => {
    return { ...config, inputType: "drawing" };
  },
  target: spread({
    targets: {
      source: $source,
      aoi: $aoi,
      modelId: $modelId,
      modelOptionIds: $modelOptionsIds,
      name: $processingName,
      // custom values
      inputType: $inputType,
    },
  }),
});

sample({
  clock: [copyProcessingForDrawingDataProvider],
  fn: (config): TCopyForDrawingParams => {
    return { ...config, inputType: "drawing" };
  },
  target: spread({
    targets: {
      source: $source,
      aoi: $aoi,
      modelId: $modelId,
      modelOptionIds: $modelOptionsIds,
      name: $processingName,
      inputType: $inputType,
    },
  }),
});

sample({
  clock: [copyProcessingForRaster],
  fn: (config): TCopyForRasterParams => {
    return {
      ...config,
      inputType: "tiff",
      file: {
        aoi: config.aoi,
        type: "tiff",
      },
    };
  },
  target: spread({
    targets: {
      source: $source,
      modelId: $modelId,
      modelOptionIds: $modelOptionsIds,
      name: $processingName,
      aoi: $aoi,
      // custom values
      inputType: $inputType,
      file: $rasterFile,
      params: $params,
    },
  }),
});

sample({
  clock: guard({
    clock: [$aoi, $source, $inputType],
    source: { aoi: $aoi, source: $source, type: $inputType, schema: $schema },
    filter: (
      params
    ): params is {
      source: SourcePayload;
      aoi: Aoi;
      type: TDataInputType;
      schema: Schema;
    } =>
      params.type === "drawing" &&
      Boolean(params.aoi && params.source && params.schema),
  }),
  fn: ({ schema, aoi, source }): TGetAllowedZoom => ({
    aoi,
    source,
    maxZoomOfAll: schema.maxZoomOfAll,
    minZoomOfAll: schema.minZoomOfAll,
  }),
  target: getAllowedZoomFx,
});

// sample({
//   clock: guard({
//     clock: $zoomInRange,
//     source: { type: $inputType, aoi: $aoi },
//     filter: ({ type, aoi }, { recommended, required }) =>
//       type === "drawing" && Boolean(aoi) && !recommended && !required,
//   }),
//   fn: () => null,
//   target: $modelId,
// });

//Calculating isDisabled
sample({
  source: {
    $inputType,
    $modelId,
    $aoi,
    $rasterFile,
    $modelOptionsIds,
  },
  fn: ({ $inputType, $modelId, $aoi, $rasterFile, $modelOptionsIds }) => {
    // below only input type === "drawing"
    if ($aoi === null) return true;

    // SAM check
    if ($modelId === ModelId.SAM) {
      return $modelOptionsIds.length === 0;
    }
    if ($inputType === "tiff") return $modelId === null || $rasterFile === null;

    // Base case
    return $modelId === null;
  },
  target: $isDisabled,
});

reset({
  clock: clearWizard,
  target: [
    $aoi,
    // $modelId,
    $modelOptionsIds,
    $selectedProcessing,
    $rasterFile,
    $inputType,
  ],
});

const wizardModel = {
  stores: {
    ...stores,
    $usefulValidResult,
    $allowedZoom,
    $isZoomsLoading: getAllowedZoomFx.pending,
    $zoomInRange,
    $preferZoom,
  },
  events,
};

export default wizardModel;
